const themeUi = {
  headerHeight: 100,
  breakpoints: ["40em", "52em", "64em"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: '"proxima-nova", sans-serif, system-ui, -apple-system, BlinkMacSystemFont',
    heading: '"Lexend", sans-serif',
    monospace: "Menlo, monospace",
  },
  fontSizes: [13, 14, 18, 20, 21, 24, 26, 30, 36, 40, 60, 70, 80, 105],
  fontWeights: {
    light: 400,
    body: 400,
    medium: 500,
    semiBold: 600,
    heading: 700,
    bold: 700,
    extraBold: 900,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: "#000",
    white: "#ffffff",
    primary: "#8C9D72",
    secondary: "#30c",
    muted: "#f6f6f6",
    success: "#0fbb0f",
    textLight: "#F8F8F8",
    footerWhite: "#F3F3F3",
    footerGray: "#747273",
    bgGrey: "#BEBEBE",
    bgBlue: "#073A70",
    darkBlue: "#002342",
    bgWhite: "#f7f7f7",
    footerCard: "#012040",
    lightBlue: "#023972",
    blueModuleBg: "#012040",
    borderColor: "#8C9C74",
    dateTextColor: "#999999",
    twitterBlue: "#1DA1F2",
    textBlack: "#262626",
  },
  text: {
    heading: {
      fontFamily: "heading",
      fontStyle: "normal",
      letterSpacing: "0.01em",
      color: "text",
    },
    callToActionHeading: {
      variant: "text.heading",
      fontWeight: "bold",
      fontSize: 6,
      lineHeight: "42px",
      letterSpacing: "0.06em",
      textTransform: "uppercase",
    },
    paragraph: {
      fontFamily: "body",
      fontWeight: "normal",
      fontSize: "15px",
      lineHeight: "25px",
      letterSpacing: "0.01em",
      color: "black",
      fontStyle: "normal",
    },
    footerHeading: {
      variant: "text.heading",
      fontWeight: "bold",
      fontSize: ["20px", "22px"],
      letterSpacing: "0.01em",
      lineHeight: "40px",
    },
    footerLocations: {
      variant: "text.heading",
      fontWeight: "medium",
      fontSize: ["16px", "18px"],
      letterSpacing: "0.01em",
      lineHeight: "32px",
    },
    singleProductHeading: {
      variant: "text.heading",
      fontWeight: "bold",
      fontSize: ["20px", "22px"],
      lineHeight: "40px",
    },
    productSingleTable: {
      variant: "text.heading",
      fontWeight: "heading",
      fontSize: "11px",
      letterSpacing: "0.01em",
      color: "white",
    },
    productSingleTableText: {
      variant: "text.heading",
      fontWeight: "normal",
      fontSize: "11px",
      letterSpacing: "0.01em",
    },
    productSingleTableSecond: {
      variant: "text.heading",
      fontWeight: "heading",
      fontSize: "13px",
      letterSpacing: "0.01em",
    },

    footerText: {
      fontFamily: "body",
      fontWeight: "medium",
      fontSize: "14px",
      color: "white",
    },
    productSingleText: {
      fontFamily: "body",
      fontWeight: "medium",
      fontSize: "14px",
      color: "black",
    },
    footerPhone: {
      fontFamily: "body",
      fontWeight: "medium",
      fontSize: [2, 3],
      letterSpacing: "0.01em",
    },
    callToActionText: {
      fontFamily: "body",
      fontWeight: "normal",
      fontSize: "14px",
    },
    desktopNav: {
      fontFamily: "heading",
      fontWeight: "medium",
      fontSize: "15px",
      textTransform: "uppercase",
      letterSpacing: "0.01em",
    },
    mobileNav: {
      fontFamily: "body",
      fontWeight: "medium",
      fontSize: ["16px"],
      textTransform: "uppercase",
    },
    productsButton: {
      fontFamily: "body",
      fontWeight: "bold",
      fontSize: "13px",
      color: "text",
      textTransform: "uppercase",
    },
    productsMore: {
      fontFamily: "body",
      fontWeight: "bold",
      fontSize: "18px",
      color: "primary",
      textTransform: "uppercase",
      letterSpacing: "0.08em",
    },
    termsCondition: {
      fontFamily: "body",
      fontWeight: "medium",
      fontSize: "14px",
      color: "white",
      textTransform: "uppercase",
    },
    formHeading: {
      variant: "text.heading",
      fontWeight: "bold",
      fontSize: ["20px", "22px"],
      lineHeight: "45px",
      color: "white",
    },
    galleryLink: {
      fontFamily: "body",
      fontWeight: "bold",
      fontSize: "22px",
      color: "black",
      lineHeight: "45px",
      textDecoration: "underline",
    },
    locationAddress: {
      fontFamily: "body",
      fontWeight: 550,
      fontSize: "14px",
      lineHeight: "24px",
      color: "textBlack",
      letterSpacing: "0px",
    },
    category: {
      variant: "text.heading",
      fontWeight: "semiBold",
      fontSize: "13px",
      lineHeight: "26px",
      textTransform: "uppercase",
      letterSpacing: "0.05em",
    },
  },
  forms: {
    radio: {
      color: "white",
      "&:hover": {
        color: "white",
        cursor: "pointer",
      },
    },
  },
  buttons: {
    primary: {
      fontFamily: "heading",
      color: "white",
      fontSize: "15px",
      fontWeight: "medium",
      letterSpacing: "0.08em",
      textTransform: "uppercase",
      bg: "transparent",
      border: `3px solid`,
      borderColor: "borderColor",
      "&:hover": {
        bg: "primary",
      },
    },
    secondary: {
      fontFamily: "heading",
      color: "primary",
      bg: "transparent",
      border: "none",
      fontWeight: "bold",
      textTransform: "uppercase",
      "&:hover": {
        ".half-arrow-icon": {
          stroke: "black",
        },
        color: "black",
        bg: "transparent",
      },
    },
    formButton: {
      fontFamily: "heading",
      color: "white",
      bg: "primary",
      border: "none",
      fontWeight: "bold",
      textTransform: "uppercase",
      ".half-arrow-icon": {
        stroke: "white",
      },
      "&:hover": {
        ".half-arrow-icon": {
          stroke: "primary",
        },
        color: "primary",
        bg: "darkBlue",
      },
    },
    products: {
      fontFamily: "heading",
      color: "primary",
      bg: "transparent",
      fontSize: "15px !important",
      border: "none",
      fontWeight: "bold",
      textTransform: "uppercase",
      "&:hover": {
        ".half-arrow-icon": {
          stroke: "black",
        },
        color: "black",
        bg: "transparent",
      },
    },
    icon: {
      color: "primary",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontStyle: "normal",
      color: "text",

      p: {
        fontSize: "15px",
        lineHeight: "25px",
      },
      a: {
        variant: "links.hyperlink",
        textDecoration: "none",
      },
      h1: {
        variant: "text.heading",
        fontWeight: "bold",
        fontSize: "27px",
        lineHeight: "40px",
        color: "text",
        letterSpacing: ["2.8px", "3px"],
      },
      h2: {
        variant: "text.heading",
        fontWeight: "bold",
        fontSize: 5,
        lineHeight: "40px",
      },
      h3: {
        variant: "text.heading",
        fontWeight: "medium",
        fontSize: [4, 5],
        lineHeight: "35px",
      },
      h4: {
        variant: "text.heading",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "26px",
        letterSpacing: "2.5px",
      },
      h5: {
        variant: "text.heading",
        fontWeight: "normal",
        fontSize: 3,
        lineHeight: "26px",
        color: "text",
      },
      h6: {
        variant: "text.heading",
        fontWeight: "bold",
        fontSize: 4,
        lineHeight: "22px",
        color: "text",
      },
      pre: {
        fontFamily: "monospace",
        overflowX: "auto",
        code: {
          color: "inherit",
        },
      },
      code: {
        fontFamily: "monospace",
        fontSize: "inherit",
      },
      table: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: 0,
      },
      tbody: {
        mt: "30px",
      },
      th: {
        textAlign: "left",
        borderBottomStyle: "solid",
        pmb: 4,
      },
      td: {
        textAlign: "left",
        borderBottomStyle: "solid",
      },
      "ul > li": {
        listStyle: "disc",
      },
      blockquote: {
        variant: "text.heading",
        fontWeight: "medium",
        fontSize: [2, "20px"],
        lineHeight: "26px",
      },
    },
    locationHero: {
      letterSpacing: "3px",
      fontSize: 6,
    },
    postHeader: {
      variant: "text.heading",
      fontWeight: "bold",
      fontSize: "27px",
      lineHeight: "40px",
      color: "text",
      letterSpacing: "2.8px",
    },
    archiveHeader: {
      variant: "text.heading",
      fontSize: "18px",
      lineHeight: "24px",
      textTransform: "uppercase",
      fontWeight: "500 !important",
    },
    dateText: {
      variant: "styles.heading",
      fontWeight: "normal",
      fontSize: ["14px", "16px"],
      textTransform: "uppercase",
      color: "dateTextColor",
      lineHeight: "26px",
      letterSpacing: "2.2px",
    },
    h4name: {
      variant: "text.heading",
      fontWeight: "normal",
      fontSize: 6,
      lineHeight: "22px",
    },
    heroHeading: {
      variant: "text.heading",
      fontWeight: "semiBold",
      fontSize: [11, 12, 13],
      "@media (max-width: 380px) ": {
        fontSize: 10,
      },
      textTransform: "uppercase",
      letterSpacing: [".05em", ".1em"],
      color: "white",
    },
    heroText: {
      variant: "text.heading",
      fontSize: ["20px", "22px"],
      "@media (max-width: 380px) ": {
        fontSize: "18px",
      },
      color: "white",
    },
    cardHeading: {
      variant: "text.heading",
      fontSize: ["16px", "18px"],
      color: "black",
      lineHight: "40px",
      fontWeight: "medium",
      textTransform: "uppercase",
    },
    productsHeading: {
      variant: "text.heading",
      fontWeight: "semiBold",
      fontSize: "22px",
      lineHeight: "42px",
    },
  },
}

export default themeUi
