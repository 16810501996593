// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-style-guide-tsx": () => import("./../../../src/pages/style-guide.tsx" /* webpackChunkName: "component---src-pages-style-guide-tsx" */),
  "component---src-templates-archive-post-js": () => import("./../../../src/templates/archive/post.js" /* webpackChunkName: "component---src-templates-archive-post-js" */),
  "component---src-templates-archive-product-js": () => import("./../../../src/templates/archive/product.js" /* webpackChunkName: "component---src-templates-archive-product-js" */),
  "component---src-templates-page-default-js": () => import("./../../../src/templates/page/default.js" /* webpackChunkName: "component---src-templates-page-default-js" */),
  "component---src-templates-post-default-js": () => import("./../../../src/templates/post/default.js" /* webpackChunkName: "component---src-templates-post-default-js" */),
  "component---src-templates-product-default-js": () => import("./../../../src/templates/product/default.js" /* webpackChunkName: "component---src-templates-product-default-js" */),
  "component---src-templates-taxonomy-product-cat-tsx": () => import("./../../../src/templates/taxonomy/productCat.tsx" /* webpackChunkName: "component---src-templates-taxonomy-product-cat-tsx" */)
}

